import React from 'react';
import { Image } from 'next-images';

const CustomPageError2 = ({ message, image, messages_box }) => {
  return (
    <div>
      <article className=" max-w-sm  flex  flex-col items-center justify-center gap-5 ">
        <p className=" text-black font-semibold  text-4xl ">404</p>

        {message && (
          <h2 className="text-xl text-center ">
            {message ?? 'This page could not be found.'}
          </h2>
        )}

        {messages_box && messages_box}
      </article>
    </div>
  );
};

export default CustomPageError2;
