import React from 'react';
import MainLayout from '../../themes/blank/MainLayout';
import CustomPageError from '../../constant/CustomPageError';
import Error_image from '../../assets/Images/third_party_blocker.png';
import Image from 'next/image';
import { getReplacePageKey } from '../../utils/getReplacePageKey';
import { PAGE_KEY } from '../../constant/PageKeys';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import DynamicPagesLayout from '../../themes/blank/DynamicPagesLayout';
import CustomPageError2 from '../../constant/CustomPageError2';

const ComponentError = () => {
  const isReplacePageAvailable = getReplacePageKey(
    TYPE_CONSTANT.PAGES?.replaced_dynamic_pages,
    PAGE_KEY.error
  );
  return isReplacePageAvailable ? (
    <DynamicPagesLayout
      dynamic_page_details={{ slug: isReplacePageAvailable }}
    />
  ) : (
    <MainLayout>
      <div className="flex justify-center items-center py-20 ">
        <CustomPageError2
          messages_box={
            <div className="flex flex-col gap-2 ">
              <p className=" text-lg font-medium text-gray-800 ">
                We're sorry, but the page does not exist. There are several
                possible options to identify the issue:
              </p>
              <ul className="px-2  list-disc flex flex-col gap-2">
                <li className=" text-lg font medium text-gray-800 ">
                  The domain you entered is not found. Please make sure you
                  typed it correctly and try again later. If you need any
                  assistance, please contact our support team.
                </li>
                <li className=" text-lg font medium text-gray-800 ">
                  If you are using Chrome and currently in the Tradly Editor,
                  please check the eye icon in the URL address bar and allow
                  third-party cookies for this website to load. (This applies
                  only to the Editor.)
                  <div className=" h-[200px] w-auto  relative   ">
                    <Image
                      src={Error_image}
                      alt={'Image'}
                      title={'BlockerError_image'}
                      layout="fill"
                      objectFit="cover"
                    />
                  </div>
                </li>
              </ul>
            </div>
          }
        />
      </div>
    </MainLayout>
  );
};

export default ComponentError;
